// flex stuff
.flex {
  display: flex;
}

.flex-1 {
  flex-grow: 1;
}

// flex alignments
.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

// margins, paddings
.m-0 {
  margin: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.p-2 { padding: 2px; }

// WTH`?
.z-20 {
  z-index: 20;
}
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333%;
}

.w-2\/3 {
  width: 66.666%;
}

.w-auto {
  width: auto;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

.flex-grow {
  flex-grow: 1;
}

.text-white {
  color: white;
}
.text-black {
  color: black;
}
.bg-blue {
  background-color: cornflowerblue;
}
.bg-white {
  background-color: white;
}


.flex-wrap	{ flex-wrap: wrap; }
.flex-wrap-reverse {	flex-wrap: wrap-reverse; }
.flex-nowrap	{ flex-wrap: nowrap; }

.justify-start {justify-content: flex-start;}
.justify-end	{ justify-content: flex-end; }
.justify-center {	justify-content: center; }
.justify-between {	justify-content: space-between; }
.justify-around {	justify-content: space-around; }
.justify-evenly {	justify-content: space-evenly; }

.flex-row {	flex-direction: row; }
.flex-row-reverse {	flex-direction: row-reverse; }
.flex-col {	flex-direction: column; }
.flex-col-reverse {	flex-direction: column-reverse; }

.items-start {	align-items: flex-start; }
.items-end {	align-items: flex-end; }
.items-center {	align-items: center; }
.items-baseline {	align-items: baseline; }
.items-stretch {	align-items: stretch; }

.cursor-default	{ cursor: default; }
.cursor-pointer {	cursor: pointer; }
.cursor-wait {	cursor: wait; }
.cursor-not-allowed {	cursor: not-allowed; }